import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import agent from '../api';
import logger from '../common/logger';

const loggingMetaName = 'Reducer: src/reducers/trainingMatch.js';

function serializeError(error) {
  if (error instanceof Error) {
    return {
      name: error.name,
      message: error.message,
      stack: error.stack,
      code: error.code,
    };
  }

  if (typeof error === 'object' && error !== null) {
    return error;
  }

  return { message: String(error) };
}

export const getTrainingMatch = createAsyncThunk(
  'trainingMatch/getTrainingMatch',
  async (id, { rejectWithValue }) => {
    try {
      logger.logDebug(`${loggingMetaName}`, `getTrainingMatch called with id: ${id}`);
      const response = await agent.TrainingMatches.get(id);
      return response.data;
    } catch (error) {
      logger.logError(`${loggingMetaName}`, `getTrainingMatch error: ${error}`);
      const serializedError = serializeError(error);      
      return rejectWithValue(serializedError);
    }
  }
);

export const deleteTrainingMatch = createAsyncThunk(
  'trainingMatch/deleteTrainingMatch',
  async (id, { rejectWithValue }) => {
    try {
      const response = await agent.TrainingMatches.del(id);
      return response.data;
    } catch (error) {
      logger.logError(`${loggingMetaName}`, `deleteTrainingMatch error: ${error}`);
      const serializedError = serializeError(error);      
      return rejectWithValue(serializedError);
    }
  }
);

export const createTrainingMatch = createAsyncThunk(
  'trainingMatch/createTrainingMatch',
  async ({ session_id, trainingMatchData }, { rejectWithValue }) => {
    try {
      const response = await agent.TrainingMatches.create(session_id, trainingMatchData);
      return response.data;
    } catch (error) {
      logger.logError(`${loggingMetaName}`, `createTrainingMatch error : ${error}`);
      return rejectWithValue(error.response?.data || serializeError(error));
    }
  },  
  { serializeError }
);

export const updateTrainingMatch = createAsyncThunk(
  'trainingMatch/updateTrainingMatch',
  async ({ matchId, trainingMatchData }, { rejectWithValue }) => {
    try {
      const response = await agent.TrainingMatches.update(matchId, trainingMatchData);
      return response.data;
    } catch (error) {
      logger.logError(`${loggingMetaName}`, `updateTrainingMatch error : ${error}`);
      return rejectWithValue(error.response?.data || serializeError(error));
    }
  }
);

const initialState = {
  trainingSessionMatch: undefined,
  inProgress: false,
  errors: undefined,
};

const trainingMatchSlice = createSlice({
  name: 'trainingMatch',
  initialState,
  reducers: {
    trainingMatchPageUnloaded: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTrainingMatch.fulfilled, (state, action) => {
      state.trainingSessionMatch = action.payload;
      state.inProgress = false;
    });

    builder.addCase(createTrainingMatch.fulfilled, (state) => {
      state.inProgress = false;
    });

    builder.addCase(deleteTrainingMatch.fulfilled, (state) => {
      state.inProgress = false;
    });

    builder.addCase(createTrainingMatch.rejected, (state, action) => {
      state.errors = action.payload || action.error;
      state.inProgress = false;
    });

    builder.addCase(deleteTrainingMatch.rejected, (state, action) => {      
      logger.logError(`${loggingMetaName}`, `Delete Training Match Rejected : ${action.payload || action.error}`);
      state.errors = action.payload || action.error;
      state.inProgress = false;
    });

    builder.addCase(updateTrainingMatch.fulfilled, (state) => {
      state.inProgress = false;
    });

    builder.addCase(updateTrainingMatch.rejected, (state, action) => {
      state.errors = action.payload || action.error;
      state.inProgress = false;
    });

    builder.addMatcher(
      (action) => action.type.endsWith('/pending'),
      (state) => {
        state.inProgress = true;
      }
    );

    builder.addDefaultCase((state) => {
      state.inProgress = false;
    });
  },
});

export const { trainingMatchPageUnloaded } = trainingMatchSlice.actions;
export default trainingMatchSlice.reducer;