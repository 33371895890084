
/**
 * @typedef {Object} Profile
  * @property {Integer} id
 * @property {String} username
 * @property {String} bio
 * @property {String} image
 * @property {String} first_name
 * @property {String} last_name
 * @property {Boolean} following
 * @property {String} belt_name
 * @property {Integer} gym_id
 */



const Profiles = (requests) => ({
  /**
   * Get all Profiles
   *
   * @param {Object} query Profile's query parameters
   * @returns {Promise<ProfilesResponse>}
   */
  all: (query) => requests.get(`/profiles`, query),

  /**
   * Get one Profile by ID
   *
   * @param {Number} id Profile's ID
   * @returns {Promise<ProfileResponse>}
   */
  get: (id) => requests.get(`/profiles/${id}`),

  /**
   * Create a new Profile
   *
   * @param {Object} profile
    * @param {Integer} profile.id
   * @param {String} profile.username
   * @param {String} profile.bio
   * @param {String} profile.image
   * @param {String} profile.first_name
   * @param {String} profile.last_name
   * @param {Boolean} profile.following
   * @param {String} profile.belt_name
   * @param {Integer} profile.gym_id
   * @returns {Promise<ProfileResponse>}
   */
  create: (profile) => requests.post(`/profiles`, { profile }),

  /**
   * Update one Profile
   *
   * @param {Number} id Profile's ID
   * @param {Partial<Profile>} profile
   * @returns {Promise<ProfileResponse>}
   */
  update: (id, profile) => requests.put(`/profiles/${id}`, { profile }),

  follow: (slug) => requests.post(`/profiles/${slug}/follow`),

  unfollow: (slug) => requests.post(`/profiles/${slug}/unfollow`),
  /**
   * Partially update one Profile
   *
   * @param {Number} id Profile's ID
   * @param {Partial<Profile>} profile
   * @returns {Promise<ProfileResponse>}
   */
  partialUpdate: (id, profile) => requests.patch(`/profiles/${id}`, { profile }),

  /**
   * Remove one Profile
   *
   * @param {Number} id Profile's ID
   * @returns {Promise<{}>}
   */
  del: (id) => requests.del(`/profiles/${id}`),
});

export default Profiles;
