import { configureStore } from '@reduxjs/toolkit';
import { localStorageMiddleware } from './middleware';
import history from './history';

import authReducer from '../features/auth/authSlice';
import commentsReducer from '../features/comments/commentsSlice';
import commonReducer from '../reducers/common';
import gymListReducer from '../reducers/gymList';
import profileReducer from '../reducers/profile';
import reportsReducer from '../reducers/report';
import tagsReducer from '../features/tags/tagsSlice';
import trainingMatchReducer from '../reducers/trainingMatch';
import trainingMatchListReducer from '../reducers/trainingMatchList';
import trainingOutcomeReducer from '../reducers/trainingOutcome';
import trainingOutcomeListReducer from '../reducers/trainingOutcomeList';
import trainingSessionReducer from '../reducers/trainingSession';
import trainingSessionListReducer from '../reducers/trainingSessionList';


export function makeStore(preloadedState) {
  return configureStore({
    reducer: {
      trainingSession: trainingSessionReducer,
      trainingSessionList: trainingSessionListReducer,
      trainingMatch: trainingMatchReducer,
      trainingMatchList: trainingMatchListReducer,
      trainingOutcome: trainingOutcomeReducer,
      trainingOutcomeList: trainingOutcomeListReducer,
      gymList: gymListReducer,
      reports: reportsReducer,
      auth: authReducer,
      comments: commentsReducer,
      common: commonReducer,
      profile: profileReducer,
      tags: tagsReducer      
    },
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),      
      localStorageMiddleware,
    ],
  });
}

const store = makeStore();

export default store;
