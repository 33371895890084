// src/index.js
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './components/App';
import store from './app/store';

const LOGGLY_CONFIG = {
  token: process.env.REACT_APP_LOGGLY_TOKEN,
  subdomain: process.env.REACT_APP_LOGGLY_SUBDOMAIN,
  tags: [process.env.REACT_APP_LOGGER_APP_TAG],
  json: true,
};

// Create the root and render the application
createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
    <Provider store={store}>      
        <Router>
          <App />
        </Router>      
    </Provider>
  // </React.StrictMode>
);

// Expose the Redux store in Cypress for testing
/* istanbul ignore else */
if (window.Cypress) {
  window.store = store;
}