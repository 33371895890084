
/**
 * @typedef {Object} Belt
  * @property {Integer} id
 * @property {Integer} parent_belt
 * @property {String} name
 */



const Belts = (requests) => ({
    /**
     * Get all Belts
     *
     * @param {Object} query Belt's query parameters
     * @returns {Promise<BeltsResponse>}
     */
    all: (query) => requests.get(`/belts`, query),

    /**
     * Get one Belt by ID
     *
     * @param {Number} id Belt's ID
     * @returns {Promise<BeltResponse>}
     */
    get: (id) => requests.get(`/belts/${id}`),

    /**
     * Create a new Belt
     *
     * @param {Object} belt
      * @param {Integer} belt.id
     * @param {Integer} belt.parent_belt
     * @param {String} belt.name
     * @returns {Promise<BeltResponse>}
     */
    create: (belt) => requests.post(`/belts`, { belt }),

    /**
     * Update one Belt
     *
     * @param {Number} id Belt's ID
     * @param {Partial<Belt>} belt
     * @returns {Promise<BeltResponse>}
     */
    update: (id, belt) => requests.put(`/belts/${id}`, { belt }),

    /**
     * Partially update one Belt
     *
     * @param {Number} id Belt's ID
     * @param {Partial<Belt>} belt
     * @returns {Promise<BeltResponse>}
     */
    partialUpdate: (id, belt) => requests.patch(`/belts/${id}`, { belt }),

    /**
     * Remove one Belt
     *
     * @param {Number} id Belt's ID
     * @returns {Promise<{}>}
     */
    del: (id) => requests.del(`/belts/${id}`),
});

export default Belts;
