import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownToggle, Navbar, NavbarToggler, NavbarBrand, Collapse, DropdownMenu, DropdownItem, Nav, NavItem, NavLink } from 'reactstrap';
import { useNavigate } from 'react-router';
import { selectIsAuthenticated, selectUser, logout} from '../features/auth/authSlice';
// import { post } from '../api'; // Adjust the import based on your project structure

/**
 * Navbar when there isn't a logged user
 *
 * @example
 * <LoggedOutNavbar />
 */
function LoggedOutNavbar() {
  return (
    <Nav className="ms-auto" navbar>
      <NavItem>
        <NavLink tag={Link} to="/login" className="nav-link">
          Sign in
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} to="/register" className="nav-link">
          Sign up
        </NavLink>
      </NavItem>
    </Nav>
  );
}

/**
 * Navbar when there is a logged user
 *
 * @example
 * <LoggedInNavbar />
 */
function LoggedInNavbar() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavBar = () => setIsOpen(!isOpen);

  const navigate = useNavigate();


  const logoutUser = () => {
    dispatch(logout());
  };

  return (
    <>
      <NavbarToggler onClick={toggleNavBar} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto" navbar>
          <NavItem>
            <NavLink tag={Link} to="/" className="nav-link">
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/training-sessions/editor" className="nav-link">
              Record Training
            </NavLink>
          </NavItem>
          <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropDown}>
            <DropdownToggle nav caret>
              <img
                src={
                  currentUser?.image ||
                  'https://static.productionready.io/images/smiley-cyrus.jpg'
                }
                className="user-pic"
                alt={currentUser?.username}
              />
              {currentUser?.username}
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem tag={Link} to="/settings">Profile</DropdownItem>
              <DropdownItem tag={Link} to="/training-sessions">Trainings</DropdownItem>
              <DropdownItem tag={Link} to="/reports">Reports</DropdownItem>
              <DropdownItem onClick={logoutUser}>Sign out</DropdownItem> 
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Collapse>
    </>
  );
}

/**
 * App header
 *
 * @example
 * <Header />
 */
function Header() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const appName = useSelector((state) => state.common.appName);

  return (
    <Navbar color="light" light expand="lg">      
     <NavbarBrand href="/">{appName.toUpperCase()}</NavbarBrand>
        {/* <Link to="/" className="navbar-brand">
          
        </Link> */}
        {isAuthenticated ? <LoggedInNavbar  /> : <LoggedOutNavbar />}      
    </Navbar>
  );
}

export default memo(Header);
















//CHat GPT

// import React, { memo, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import {
//   Navbar,
//   NavbarToggler,
//   Collapse,
//   Nav,
//   NavItem,
//   NavLink,
//   UncontrolledDropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem
// } from 'reactstrap';

// import { selectIsAuthenticated, selectUser } from '../features/auth/authSlice';
// // import { post } from '../api'; // Adjust the import based on your project structure

// /**
//  * Navbar when there isn't a logged user
//  *
//  * @example
//  * <LoggedOutNavbar />
//  */
// function LoggedOutNavbar() {
//   return (
//     <Nav className="ms-auto" navbar>
//       <NavItem>
//         <NavLink tag={Link} to="/" className="nav-link">
//           Home
//         </NavLink>
//       </NavItem>
//       <NavItem>
//         <NavLink tag={Link} to="/login" className="nav-link">
//           Sign in
//         </NavLink>
//       </NavItem>
//       <NavItem>
//         <NavLink tag={Link} to="/register" className="nav-link">
//           Sign up
//         </NavLink>
//       </NavItem>
//     </Nav>
//   );
// }

// /**
//  * Navbar when there is a logged user
//  *
//  * @example
//  * <LoggedInNavbar />
//  */
// function LoggedInNavbar() {
//   const currentUser = useSelector(selectUser);
//   const [isOpen, setIsOpen] = useState(false);
//   const toggleNavBar = () => setIsOpen(!isOpen);

//   return (
//     <>
//       <NavbarToggler onClick={toggleNavBar} />
//       <Collapse isOpen={isOpen} navbar>
//         <Nav className="ms-auto" navbar>
//           <NavItem>
//             <NavLink tag={Link} to="/" className="nav-link">
//               Home
//             </NavLink>
//           </NavItem>
//           <NavItem>
//             <NavLink tag={Link} to="/training-sessions/editor" className="nav-link">
//               Record Training
//             </NavLink>
//           </NavItem>
//           <UncontrolledDropdown nav inNavbar>
//             <DropdownToggle nav caret>
//               <img
//                 src={
//                   currentUser?.image ||
//                   'https://static.productionready.io/images/smiley-cyrus.jpg'
//                 }
//                 className="user-pic"
//                 alt={currentUser?.username}
//               />
//               {currentUser?.username}
//             </DropdownToggle>
//             <DropdownMenu end>
//               <DropdownItem tag={Link} to="/settings">Profile</DropdownItem>
//               <DropdownItem tag={Link} to="/training-sessions">Trainings</DropdownItem>
//               <DropdownItem tag={Link} to="/reports">Reports</DropdownItem>
//               {/* <DropdownItem onClick={logout}>Sign out</DropdownItem> */}
//             </DropdownMenu>
//           </UncontrolledDropdown>
//         </Nav>
//       </Collapse>
//     </>
//   );
// }

// /**
//  * App header
//  *
//  * @example
//  * <Header />
//  */
// function Header() {
//   const isAuthenticated = useSelector(selectIsAuthenticated);
//   const appName = useSelector((state) => state.common.appName);

//   return (
//     <Navbar color="light" light expand="lg">
//       <div className="container">
//         <Link to="/" className="navbar-brand">
//           {appName.toLowerCase()}
//         </Link>
//         {isAuthenticated ? <LoggedInNavbar /> : <LoggedOutNavbar />}
//       </div>
//     </Navbar>
//   );
// }

// export default memo(Header);