import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import agent from '../api';

function serializeError(error) {
  if (error instanceof Error) {
    return {
      name: error.name,
      message: error.message,
      stack: error.stack,
      code: error.code,
    };
  }

  if (typeof error === 'object' && error !== null) {
    return error;
  }

  return { message: String(error) };
}

export const getTrainingSession = createAsyncThunk(
  'trainingSession/getTrainingSession',
  agent.TrainingSessions.get
);

export const deleteTrainingSession = createAsyncThunk(
  'trainingSession/deleteTrainingSession',
  agent.TrainingSessions.del
);

export const createTrainingSession = createAsyncThunk(
  'trainingSession/createTrainingSession',
  agent.TrainingSessions.create,
  { serializeError }
);

export const updateTrainingSession = createAsyncThunk(
  'trainingSession/updateTrainingSession',
  async ({ sessionId, trainingSessionData }, { rejectWithValue }) => {
    try {
      const response = await agent.TrainingSessions.update(sessionId, trainingSessionData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  trainingSession: undefined,
  inProgress: false,
  errors: undefined,
};

const trainingSessionSlice = createSlice({
  name: 'trainingSession',
  initialState,
  reducers: {
    trainingSessionPageUnloaded: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTrainingSession.fulfilled, (state, action) => {
      state.trainingSession = action.payload;
      state.inProgress = false;
    });

    builder.addCase(createTrainingSession.fulfilled, (state) => {
      state.inProgress = false;
    });

    builder.addCase(deleteTrainingSession.fulfilled, (state) => {
      state.inProgress = false;
    });

    builder.addCase(createTrainingSession.rejected, (state, action) => {
      state.errors = action.error.errors;
      state.inProgress = false;
    });


    builder.addCase(deleteTrainingSession.rejected, (state, action) => {
      state.errors = action.error.errors;
      state.inProgress = false;
    });

    builder.addCase(updateTrainingSession.fulfilled, (state) => {
      state.inProgress = false;
    });

    builder.addCase(updateTrainingSession.rejected, (state, action) => {
      state.errors = action.error.errors;
      state.inProgress = false;
    });

    builder.addMatcher(
      (action) => action.type.endsWith('/pending'),
      (state) => {
        state.inProgress = true;
      }
    );

    builder.addDefaultCase((state) => {
      state.inProgress = false;
    });
  },
});

export const { trainingSessionPageUnloaded } = trainingSessionSlice.actions;

export default trainingSessionSlice.reducer;
