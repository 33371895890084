import React, { lazy, Suspense, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Home from '../components/Home';
import { appLoad, clearRedirect } from '../reducers/common';
import Header from './Header';



const ReifersOpponentReport = lazy(() =>
  import(
    /* webpackChunkName: "ReifersOpponentReport", webpackPrefetch: true  */ './Reports/Reifers/Opponent'
  )
);


const ReifersReport = lazy(() =>
  import(
    /* webpackChunkName: "ReifersReport", webpackPrefetch: true  */ './Reports/Reifers/'
  )
);

const Reports = lazy(() =>
  import(
    /* webpackChunkName: "Reports", webpackPrefetch: true  */ './Reports/'
  )
);


const TrainingSessions = lazy(() =>
  import(
    /* webpackChunkName: "TrainingSessions", webpackPrefetch: true  */ './TrainingSessions/'
  )
);

const TrainingMatches = lazy(() =>
  import(
    /* webpackChunkName: "TrainingMatches", webpackPrefetch: true  */ './TrainingSessions/Matches/'
  )
);

const TrainingSessionEditor = lazy(() =>
  import(
    /* webpackChunkName: "TrainingSessionEditor", webpackPrefetch: true  */ './TrainingSessions/Editor'
  )
);


const AuthScreen = lazy(() =>
  import(
    /* webpackChunkName: "AuthScreen", webpackPrefetch: true  */ '../features/auth/AuthScreen'
  )
);
const Profile = lazy(() =>
  import(
    /* webpackChunkName: "Profile", webpackPrefetch: true  */ '../components/Profile'
  )
);
const SettingsScreen = lazy(() =>
  import(
    /* webpackChunkName: "SettingsScreen", webpackPrefetch: true  */ '../features/auth/SettingsScreen'
  )
);

function App() {
  const dispatch = useDispatch();
  const redirectTo = useSelector((state) => state.common.redirectTo);
  const appLoaded = useSelector((state) => state.common.appLoaded);

  useEffect(() => {
    if (redirectTo) {
      // dispatch(push(redirectTo));
      dispatch(clearRedirect());
    }
  }, [redirectTo]);

  useEffect(() => {
    const token = window.localStorage.getItem('jwt');
    dispatch(appLoad(token));
  }, []);

  if (appLoaded) {
    return (
      <>
        <Header />
        <Suspense fallback={<p>Loading...</p>}>
          <Routes>
            <Route exact path="/" element={<Home />} />            
            <Route path="/login" element={<AuthScreen />} />
            <Route path="/register" element={<AuthScreen isRegisterScreen />} />                                    
            <Route path="/reports" element={<Reports />} />
            <Route path="/reports/reifers" element={<ReifersReport />} />
            <Route path="/reports/reifers/opponent/:profile_id" element={<ReifersOpponentReport />} />
            <Route path="/settings" element={<SettingsScreen />} />
            <Route path="/training-sessions" element={<TrainingSessions />} />
            <Route path="/training-sessions/:training_session_id" element={<TrainingMatches />} />
            <Route path="/training-sessions/editor" element={<TrainingSessionEditor />} />
            <Route path="/training-sessions/:training_session_id/editor" element={<TrainingSessionEditor />} />
            <Route path="/training-sessions/:training_session_id/matches" element={<TrainingMatches />} />
            
            
            <Route
              path="/@:username/favorites"
              element={<Profile isFavoritePage />}
            />
            <Route path="/@:username" element={<Profile />} />
          </Routes>
        </Suspense>
      </>
    );
  }
  return (
    <>
      <Header />
      <p>Loading...</p>
    </>
  );
}

export default memo(App);
