import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Banner from './Banner';
import MainView from './MainView';
import { selectIsAuthenticated } from '../../features/auth/authSlice';

/**
 * Home screen component
 *
 * @example
 * <Home />
 */
function Home() {

  return (
    <div className="home-page">
      <Banner />
      <div className="container page">
        <div className="row">
          <MainView />
        </div>
      </div>
    </div>
  );
}

export default memo(Home);
