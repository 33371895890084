
/**
 * @typedef {Object} Email
  * @property {String} email
 */



const Emails = (requests) => ({
    /**
     * Get all Emails
     *
     * @param {Object} query Email's query parameters
     * @returns {Promise<EmailsResponse>}
     */
    all: (query) => requests.get(`/emails`, query),

    /**
     * Get one Email by ID
     *
     * @param {Number} id Email's ID
     * @returns {Promise<EmailResponse>}
     */
    get: (id) => requests.get(`/emails/${id}`),

    /**
     * Create a new Email
     *
     * @param {Object} email
      * @param {String} email.email
     * @returns {Promise<EmailResponse>}
     */
    create: (email) => requests.post(`/emails`, { email }),

    /**
     * Update one Email
     *
     * @param {Number} id Email's ID
     * @param {Partial<Email>} email
     * @returns {Promise<EmailResponse>}
     */
    update: (id, email) => requests.put(`/emails/${id}`, { email }),

    /**
     * Partially update one Email
     *
     * @param {Number} id Email's ID
     * @param {Partial<Email>} email
     * @returns {Promise<EmailResponse>}
     */
    partialUpdate: (id, email) => requests.patch(`/emails/${id}`, { email }),

    /**
     * Remove one Email
     *
     * @param {Number} id Email's ID
     * @returns {Promise<{}>}
     */
    del: (id) => requests.del(`/emails/${id}`),
});

export default Emails;
