// src/Reports/Training/BeltBars.jsx
import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Col, Container, Row } from 'reactstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import logger from '../../../common/logger';

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BeltBars = ({ belt_bars_dataset, belt_bars_data_labels }) => {
  if (!belt_bars_dataset || !belt_bars_data_labels) {
    logger.logError('src/Reports/Training/BeltBars.jsx', 'BeltBars received undefined datasets or labels');
    return null;
  }

  const data = {
    datasets: belt_bars_dataset,
    labels: belt_bars_data_labels,
  };

      // Log the data to check the contents
      logger.logDebug('src/Reports/Training/BeltBars.jsx', 'Rendering BeltBars with chart data: ', data);


  return (
    <Container>
          <Bar data={data} />
    </Container>
  );
};

export default BeltBars;