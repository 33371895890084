import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import agent from '../api';

function serializeError(error) {
  if (error instanceof Error) {
    return {
      name: error.name,
      message: error.message,
      stack: error.stack,
      code: error.code,
    };
  }

  if (typeof error === 'object' && error !== null) {
    return error;
  }

  return { message: String(error) };
}

export const getTrainingMatchOutcome = createAsyncThunk(
  'trainingMatchOutcome/getTrainingMatchOutcome',
  agent.TrainingOutcomes.get
);

export const deleteTrainingMatchOutcome = createAsyncThunk(
  'trainingMatchOutcome/deleteTrainingMatchOutcome',
  async (id, { rejectWithValue }) => {
    try {
      const response = await agent.TrainingOutcomes.del(id);
      return response.data;
    } catch (error) {
      console.error('Error deleting training match:', error);
      const serializedError = serializeError(error);
      console.error('Serialized Error:', serializedError);
      return rejectWithValue(serializedError);
    }
  }
);

export const createTrainingMatchOutcome = createAsyncThunk(
  'trainingMatchOutcome/createTrainingMatchOutcome',
  async ({ match_id, trainingMatchOutcomeData }, { rejectWithValue }) => {
    try {
      const response = await agent.TrainingOutcomes.create(match_id, trainingMatchOutcomeData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || serializeError(error));
    }
  },  
  { serializeError }
);

export const updateTrainingMatchOutcome = createAsyncThunk(
  'trainingMatchOutcome/updateTrainingMatchOutcome',
  async ({ outcomeId, trainingMatchOutcomeData }, { rejectWithValue }) => {
    try {
      const response = await agent.TrainingOutcomes.update(outcomeId, trainingMatchOutcomeData);      
    } catch (error) {
      return rejectWithValue(error.response?.data || serializeError(error));
    }
  }
);

const initialState = {
  trainingMatchOutcome: undefined,
  inProgress: false,
  errors: undefined,
};

const trainingMatchOutcomeSlice = createSlice({
  name: 'trainingMatchOutcome',
  initialState,
  reducers: {
    trainingMatchOutcomePageUnloaded: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTrainingMatchOutcome.fulfilled, (state, action) => {
      state.trainingMatchOutcome = action.payload;
      state.inProgress = false;
    });

    builder.addCase(createTrainingMatchOutcome.fulfilled, (state) => {
      state.inProgress = false;
    });

    builder.addCase(deleteTrainingMatchOutcome.fulfilled, (state) => {
      state.inProgress = false;
    });

    builder.addCase(createTrainingMatchOutcome.rejected, (state, action) => {
      state.errors = action.payload || action.error;
      state.inProgress = false;
    });

    builder.addCase(deleteTrainingMatchOutcome.rejected, (state, action) => {
      console.log('Delete Training MatchOutcome Rejected:', action.payload, action.error);
      state.errors = action.payload || action.error;
      state.inProgress = false;
    });

    builder.addCase(updateTrainingMatchOutcome.fulfilled, (state) => {
      state.inProgress = false;
    });

    builder.addCase(updateTrainingMatchOutcome.rejected, (state, action) => {
      state.errors = action.payload || action.error;
      state.inProgress = false;
    });

    builder.addDefaultCase((state) => {
      state.inProgress = false;
    });
  },
});

export const { trainingMatchOutcomePageUnloaded } = trainingMatchOutcomeSlice.actions;
export default trainingMatchOutcomeSlice.reducer;