// src/reducers/gymList.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agent from '../api';
import logger from '../common/logger';

const loggingMetaName = 'Reducer : /src/reducers/gymList.js';

export const changeTab = (tab) => (dispatch) => {
  dispatch(gymListSlice.actions.changeTab(tab));  
  return dispatch(getAllGyms());
};

export const getAllGyms = createAsyncThunk(
  'gymList/getAll',
  async ({ page = 1, gym_name = '', limit = 10 } = {}, thunkApi) => {
    const state = thunkApi.getState();
    const currentPage = page ?? state.gymList.currentPage;    
  
    try {
      logger.logDebug(`${loggingMetaName}`, 'Fetching gyms', {
        tab: state.gymList.tab,
        currentPage,
        limit,        
        gym_name
      });

      const response = await agent.Gyms.all({
        page: currentPage,            
        gym_name,
        limit,
      });

      logger.logDebug(`${loggingMetaName}`, 'Fetched gyms successfully : ', { response });
      return response;
    } catch (error) {
      logger.logError(`${loggingMetaName}`, 'Error fetching gyms : ', { error });
      throw error;
    }
  }
);

const initialState = {
  gyms: [],
  gymsCount: 0,
  currentPage: 0,
  loading: false,
  error: null,
};

const gymListSlice = createSlice({
  name: 'gymList',
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
    changeTab: (state, action) => {
      state.tab = action.payload;
      delete state.tag;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGyms.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllGyms.fulfilled, (state, action) => {
        logger.logDebug(`${loggingMetaName}`, `getAllGyms.fulfilled action.payload.results: ${action.payload.results}`);        
        state.gyms = action.payload.results;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllGyms.rejected, (state, action) => {
        logger.logError(`${loggingMetaName}`, `getAllGyms.rejected action.error: ${action.error}`);
        state.loading = false;
        state.error = action.error.message;
      });   
  },
});

export const { homePageUnloaded } = gymListSlice.actions;

export default gymListSlice.reducer;