/**
 * @typedef {Object} ApiError
 * @property {{[property: string]: string}} errors
 * 
 * @typedef {Object} Gym
 * @property {Number} id
 * @property {String} name
 * @property {String} address
 * @property {String} professor
 * @property {String} website
 * @property {String} phone_number
 * @property {String} facebook_page
 * 
 * @typedef {Object} GymResponse
 * @property {Gym} gym
 *
 * @typedef {Object} GymsResponse
 * @property {Gym[]} gyms
 * @property {Number} gymsCount
 *
 */

const Gyms = (requests) => ({
    /**
     * Get all gyms
     *
     * @param {Object} query Gym's query parameters
     * @param {String} [query.ordering]
     * @param {Number} [query.page]
     * @returns {Promise<GymsResponse>}
     */
    all: (query) => requests.get(`/gyms`, query),
  
    /**
     * Get one gym by ID
     *
     * @param {Number} id Gym's ID
     * @returns {Promise<GymResponse>}
     */
    get: (id) => requests.get(`/gyms/${id}`),
  
    /**
     * Create a new gym
     *
     * @param {Object} gym
     * @param {String} gym.name
     * @param {String} gym.address
     * @param {String} gym.professor
     * @param {String} gym.website
     * @param {String} gym.phone_number
     * @param {String} gym.facebook_page
     * @returns {Promise<GymResponse>}
     */
    create: (gym) => requests.post('/gyms', { gym }),
  
    /**
     * Update one gym
     *
     * @param {Number} id Gym's ID
     * @param {Partial<Gym>} gym
     * @returns {Promise<GymResponse>}
     */
    update: (id, gym) => requests.put(`/gyms/${id}`, { gym }),
  
    /**
     * Partially update one gym
     *
     * @param {Number} id Gym's ID
     * @param {Partial<Gym>} gym
     * @returns {Promise<GymResponse>}
     */
    partialUpdate: (id, gym) => requests.patch(`/gyms/${id}`, { gym }),
  
    /**
     * Remove one gym
     *
     * @param {Number} id Gym's ID
     * @returns {Promise<{}>}
     */
    del: (id) => requests.del(`/gyms/${id}`),
  });
  
  export default Gyms;