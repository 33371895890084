import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Col, Container, Row } from 'reactstrap';
import logger from '../../../common/logger';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TechniqueLines = ({ technique_lines_datasets, technique_lines_data_labels }) => {
  if (!technique_lines_datasets || !technique_lines_data_labels) {
    logger.logError('src/Reports/Training/TechniqueLines.jsx', 'TechniqueLines received undefined datasets or labels');
    return null;
  }

  const data = {
    datasets: technique_lines_datasets,
    labels: technique_lines_data_labels,
  };

    // Log the data to check the contents
    logger.logDebug('src/Reports/Training/TechniqueLines.jsx', 'Rendering TechniqueLines with data: ', data);

  return (
    <Container>
        <Line data={data} />
    </Container>
  );
};

export default TechniqueLines;