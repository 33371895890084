// Helper function to ensure the query includes the format=json parameter
const ensureFormat = (query = {}) => {
    if (!query.format) {
      query.format = 'json';
    }
    return query;
  };
  
  const Reports = (requests) => ({
    /**
     * Get hours trained report
     *
     * @param {Object} query Report query parameters
     * @param {String} [query.format] Desired format of the report
     * @returns {Promise<ReportResponse>}
     */
    hoursTrained: (query) => requests.get('/reports/hours-trained', ensureFormat(query)),
  
    /**
     * Get match belt level submission details report
     *
     * @param {Object} query Report query parameters
     * @param {String} [query.format] Desired format of the report
     * @returns {Promise<ReportResponse>}
     */
    matchBeltLevelSubmissionDetails: (query) =>
      requests.get('/reports/match-beltlevel-submission-details', ensureFormat(query)),
  
    /**
     * Get match belt level tapout details report
     *
     * @param {Object} query Report query parameters
     * @param {String} [query.format] Desired format of the report
     * @returns {Promise<ReportResponse>}
     */
    matchBeltLevelTapoutDetails: (query) =>
      requests.get('/reports/match-beltlevel-tapout-details', ensureFormat(query)),
  
    /**
     * Get match technique submission details report
     *
     * @param {Object} query Report query parameters
     * @param {String} [query.format] Desired format of the report
     * @returns {Promise<ReportResponse>}
     */
    matchTechniqueSubmissionDetails: (query) =>
      requests.get('/reports/match-technique-submission-details', ensureFormat(query)),
  
    /**
     * Get match technique tapout details report
     *
     * @param {Object} query Report query parameters
     * @param {String} [query.format] Desired format of the report
     * @returns {Promise<ReportResponse>}
     */
    matchTechniqueTapoutDetails: (query) =>
      requests.get('/reports/match-technique-tapout-details', ensureFormat(query)),
  
    /**
     * Get Reifer's report with optional duration
     *
     * @param {Object} query Report query parameters
     * @param {Number} [query.duration=365] Duration in days for filtering the report
     * @returns {Promise<ReportResponse>}
     */
    reifersReport: (query) =>
      requests.get('/reports/reifers-report', ensureFormat(query)),
  
    /**
     * Get Reifer's report for a specific profile with optional duration
     *
     * @param {Number} profileId Profile ID
     * @param {Object} query Report query parameters
     * @param {Number} [query.duration=365] Duration in days for filtering the report
     * @returns {Promise<ReportResponse>}
     */
    reifersReportByProfile: (profileId, query) =>
      requests.get(`/reports/reifers-report/${profileId}`, ensureFormat(query)),
  });
  
  export default Reports;