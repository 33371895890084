// reducers/trainingSessionList.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agent from '../api';
import logger from '../common/logger';

export const getAllTrainingSessions = createAsyncThunk(
  'trainingSessionList/getAll',
  async ({ page = 1, search = '', limit = 10 } = {}, thunkApi) => {
    const state = thunkApi.getState();
    const currentTrainingSessionPage = page;
    const searchQuery = search;

    try {
      logger.logDebug('src/reducers/trainingSessionList', 'Fetching training sessions', {        
        tab: state.trainingSessionList.tab,
        currentTrainingSessionPage,        
        limit,
        searchQuery,
      });

      const response = await agent.TrainingSessions.all({
        page: page,
        search: search,
        limit: limit,
      });

      logger.logDebug('src/reducers/trainingSessionList.js', 'Fetched training sessions successfully', { response });
      return response;
    } catch (error) {
      logger.logError('src/reducers/trainingSessionList.js', 'Error fetching training sessions', { error });
      throw error;
    }
  }
);

const initialState = {
  trainingSessions: [],
  trainingSessionsCount: 0,
  currentTrainingSessionPage: 1,
  trainingSessionsPerPage: 10,
  totalPages: 0,
  tab: undefined,
  searchQuery: '',
  loading: false,
  error: null,
};

const trainingSessionListSlice = createSlice({
  name: 'trainingSessionList',
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
    changeTab: (state, action) => {
      state.tab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTrainingSessions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTrainingSessions.fulfilled, (state, action) => {
        logger.logDebug('src/reducers/trainingSessionList.js', `getAllTrainingSessions.fulfilled action.payload.results: ${action.payload.results}`);
        state.trainingSessions = action.payload.results;
        state.trainingSessionsCount = action.payload.count;
        state.totalPages = Math.ceil(action.payload.count / state.trainingSessionsPerPage);
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllTrainingSessions.rejected, (state, action) => {
        logger.logDebug('src/reducers/trainingSessionList.js', `getAllTrainingSessions.rejected action.error: ${action.error}`);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { homePageUnloaded } = trainingSessionListSlice.actions;

export default trainingSessionListSlice.reducer;