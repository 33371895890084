// src/components/Home/MainView.js
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import TraingSessionTable from '../TrainingSession/Table.js'
import TrainingReports from '../Reports/Training';
import { selectIsAuthenticated } from '../../features/auth/authSlice';



/**
 * Show the tab navigation and the list of trainingSessions
 *
 * @example
 * <MainView />
 */
function MainView() {

  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="col-md-12">
        <TrainingReports />      
    </div>
  );
}

export default memo(MainView);