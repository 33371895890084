import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agent from '../api';
import logger from '../common/logger';

const fileName = '/src/reducers/profile.js';

export const getProfile = createAsyncThunk(
  'profile/getProfile',
  agent.Profiles.get
);


export const findProfile = createAsyncThunk(
  'profile/findProfile',
  async ({ search = '' } = {}, thunkApi) => {
    const searchQuery = search;

    try {
      logger.logDebug(fileName, 'Fetching Profiles', { searchQuery });

      const response = await agent.Profiles.all({ search });

      logger.logDebug(fileName, 'Fetched profiles successfully', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'Error fetching profiles', { error });
      throw error;
    }
  }
);

export const follow = createAsyncThunk('profile/follow', agent.Profiles.follow);

export const unfollow = createAsyncThunk(
  'profile/unfollow',
  agent.Profiles.unfollow
);

const profileSlice = createSlice({
  name: 'profile',
  initialState: {},
  reducers: {
    profilePageUnloaded: () => ({}),
  },
  extraReducers: (builder) => {
    const successCaseReducer = (_, action) => ({
      ...action.payload.profile,
    });

    builder.addCase(getProfile.fulfilled, successCaseReducer);
    builder.addCase(findProfile.fulfilled, successCaseReducer);
    builder.addCase(follow.fulfilled, successCaseReducer);
    builder.addCase(unfollow.fulfilled, successCaseReducer);
  },
});

export const { profilePageUnloaded } = profileSlice.actions;

export default profileSlice.reducer;
