// src/Reports/Training/index.jsx
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import TechniqueLines from './TechniqueLines';
import BeltBars from './BeltBars';
import TrainingHours from './TrainingHours';
import { fetchReports } from '../../../reducers/report';
import logger from '../../../common/logger';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TrainingReports = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const duration = query.get('duration');
  const {
    submission_report_datasets,
    submission_report_labels,
    tapout_report_datasets,
    tapout_report_labels,
    belt_submission_report_datasets,
    belt_submission_report_labels,
    belt_tapout_report_datasets,
    belt_tapout_report_labels,
    hours_trained_data,
    hours_label_array,
    loading,
    error
  } = useSelector((state) => state.reports);

  const [activeTab, setActiveTab] = useState('belt_submissions');

  useEffect(() => {
    logger.logInfo('src/Reports/Training/index.jsx', 'TrainingReports component mounted, dispatching fetchReports');
    dispatch(fetchReports({duration: duration}));
  }, [dispatch]);

  useEffect(() => {
    logger.logInfo('src/Reports/Training/index.jsx', 'TrainingReports state updated', {
      submission_report_datasets,
      submission_report_labels,
      tapout_report_datasets,
      tapout_report_labels,
      belt_submission_report_datasets,
      belt_submission_report_labels,
      belt_tapout_report_datasets,
      belt_tapout_report_labels,
      hours_trained_data,
      hours_label_array,
      loading,
      error
    });
  }, [
    submission_report_datasets,
    submission_report_labels,
    tapout_report_datasets,
    tapout_report_labels,
    belt_submission_report_datasets,
    belt_submission_report_labels,
    belt_tapout_report_datasets,
    belt_tapout_report_labels,
    hours_trained_data,
    hours_label_array,
    loading,
    error
  ]);

  if (loading) {
    return <div className="trainingSession-preview">Loading...</div>;
  }

  if (error) {
    logger.logError('src/Reports/Training/index.jsx', 'Error fetching reports', { error });
    return <div className="trainingSession-preview">Error: {error}</div>;
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>BJJ Journey Reports</h1>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === 'belt_submissions' ? 'active' : ''}
            onClick={() => setActiveTab('belt_submissions')}
          >
            Belt Submissions
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 'belt_tapouts' ? 'active' : ''}
            onClick={() => setActiveTab('belt_tapouts')}
          >
            Belt Tap Outs
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 'submissions' ? 'active' : ''}
            onClick={() => setActiveTab('submissions')}
          >
            Technique Submissions
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 'tapouts' ? 'active' : ''}
            onClick={() => setActiveTab('tapouts')}
          >
            Technique Tap Outs
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 'hours' ? 'active' : ''}
            onClick={() => setActiveTab('hours')}
          >
            Hours Trained
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="belt_submissions">
          <h2>Belt Submissions / Month</h2>
          <BeltBars belt_bars_dataset={belt_submission_report_datasets} belt_bars_data_labels={belt_submission_report_labels} />
        </TabPane>
        <TabPane tabId="belt_tapouts">
          <h2>Belt Tap Outs / Month</h2>
          <BeltBars belt_bars_dataset={belt_tapout_report_datasets} belt_bars_data_labels={belt_tapout_report_labels} />
        </TabPane>
        <TabPane tabId="submissions">
          <h2>Technique Submissions / Month</h2>
          <TechniqueLines technique_lines_datasets={submission_report_datasets} technique_lines_data_labels={submission_report_labels} />
        </TabPane>
        <TabPane tabId="tapouts">
          <h2>Technique Tap Outs / Month</h2>
          <TechniqueLines technique_lines_datasets={tapout_report_datasets} technique_lines_data_labels={tapout_report_labels} />
        </TabPane>
        <TabPane tabId="hours">
          <h2>Total Hours Trained / Month</h2>
          <TrainingHours hours_trained_data={hours_trained_data} hours_label_array={hours_label_array} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default TrainingReports;