import agent from '../api';
import { login, logout, register } from '../features/auth/authSlice';
import logger from '../common/logger';

const localStorageMiddleware = (store) => (next) => (action) => {
  
  switch (action.type) {
    case register.fulfilled.type:
    case login.fulfilled.type:
      logger.logDebug('src/app/middleware.js', 'User logged in, setting token in localStorage', { token: action.payload.token });
      window.localStorage.setItem('jwt', action.payload.token);
      agent.setToken(action.payload.token);
      break;

    case logout.type:
      window.localStorage.removeItem('jwt');
      agent.setToken(undefined);
      logger.logDebug('src/app/middleware.js', 'User logged out, removing token from localStorage');
      break;

    default:
      // You may want to log unhandled action types
      // logger.logDebug('src/app/middleware.js', 'Middleware unhandled action type :', { type: action.type });
  }

  return next(action);
};

export { localStorageMiddleware };