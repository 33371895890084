
/**
 * @typedef {Object} Technique
  * @property {Integer} id
 * @property {String} name
 * @property {String} description
 * @property {String} alternative_name
 * @property {String} japanese_name
 * @property {Unknown} supporting_media
 */


const Techniques = (requests) => ({
    /**
     * Get all Techniques
     *
     * @param {Object} query Technique's query parameters
     * @returns {Promise<TechniquesResponse>}
     */
    all: (query) => requests.get(`/techniques`, query),

    /**
     * Get one Technique by ID
     *
     * @param {Number} id Technique's ID
     * @returns {Promise<TechniqueResponse>}
     */
    get: (id) => requests.get(`/techniques/${id}`),

    /**
     * Create a new Technique
     *
     * @param {Object} technique
      * @param {Integer} technique.id
     * @param {String} technique.name
     * @param {String} technique.description
     * @param {String} technique.alternative_name
     * @param {String} technique.japanese_name
     * @param {Unknown} technique.supporting_media
     * @returns {Promise<TechniqueResponse>}
     */
    create: (technique) => requests.post(`/techniques`, { technique }),

    /**
     * Update one Technique
     *
     * @param {Number} id Technique's ID
     * @param {Partial<Technique>} technique
     * @returns {Promise<TechniqueResponse>}
     */
    update: (id, technique) => requests.put(`/techniques/${id}`, { technique }),

    /**
     * Partially update one Technique
     *
     * @param {Number} id Technique's ID
     * @param {Partial<Technique>} technique
     * @returns {Promise<TechniqueResponse>}
     */
    partialUpdate: (id, technique) => requests.patch(`/techniques/${id}`, { technique }),

    /**
     * Remove one Technique
     *
     * @param {Number} id Technique's ID
     * @returns {Promise<{}>}
     */
    del: (id) => requests.del(`/techniques/${id}`),
});

export default Techniques;
