import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Container, Row, Col } from 'reactstrap';

const HoursTrainedChart = ({ hours_trained_data, hours_label_array }) => {
  const data = {
    labels: hours_label_array,
    datasets: [
      {
        label: "Total Hours Trained Over Time",
        data: hours_trained_data,
        backgroundColor: [
          "rgba(247, 70, 74, 0.5)",
          "rgba(70, 191, 189, 0.5)",
          "rgba(253, 180, 92, 0.5)",
          "rgba(148, 159, 177, 0.5)",
          "rgba(77, 83, 96, 0.5)"
        ],
        borderWidth: 2,
      }
    ]
  };

  return (
    <Container>
        <Bar data={data} />
    </Container>
  );
};

export default HoursTrainedChart;