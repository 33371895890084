// src/common/logger.js
import { LogglyTracker } from "loggly-jslogger";

// console.log("Process REACT_APP_LOGGLY_TOKEN: ", process.env.REACT_APP_LOGGLY_TOKEN);
// console.log("Process REACT_APP_LOG_MODE: ", process.env.REACT_APP_LOG_MODE);
// console.log("Process REACT_APP_LOG_LEVEL: ", process.env.REACT_APP_LOG_LEVEL);
// console.log("Process REACT_APP_LOGGER_APP_TAG: ", process.env.REACT_APP_LOGGER_APP_TAG);

// Create a new LogglyTracker instance
const logger = new LogglyTracker();

// Configure the Loggly tracker with the token and subdomain
logger.push({ logglyKey: process.env.REACT_APP_LOGGLY_TOKEN });

// Define log levels with corresponding numerical values for comparison
const logLevels = {
  ERROR: 4,
  WARN: 3,
  INFO: 2,
  DEBUG: 1,
};

// Get the desired logging mode from the environment variable
const LOG_MODE = process.env.REACT_APP_LOG_MODE || "BOTH"; // Options: 'LOGGLY', 'CONSOLE', 'BOTH'

// Get the desired logging level from the environment variable
const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || "INFO";

// Determine the numeric value of the configured log level
const currentLogLevel = logLevels[LOG_LEVEL.toUpperCase()] || logLevels.INFO;

// Logging function that handles log levels and modes
const logMessage = (level, source, message, meta = {}) => {  
  
  // console.log("logMessage - Log Mode: ", process.env.REACT_APP_LOG_MODE);
  // console.log("logMessage - Log Level: ", process.env.REACT_APP_LOG_LEVEL);
  // console.log("logMessage - Logger App Tag: ", process.env.REACT_APP_LOGGER_APP_TAG);
  // Ensure message is defined, even if it's an empty string or array
  if (message === undefined || message === null) {
    message = JSON.stringify(message);
  }

  const logEntry = {
    level,
    source,
    message,
    tag: process.env.REACT_APP_LOGGER_APP_TAG, // Add the tag here
    ...meta,
  };

  // Check if the current log level allows logging the message
  if (logLevels[level] >= currentLogLevel) {
    
    // Log to Loggly if the mode is 'loggly' or 'both'
    if (LOG_MODE === "LOGGLY" || LOG_MODE === "BOTH") {
      logger.push(logEntry);
    }
    
    // Log to console if the mode is 'console' or 'both'
    if (LOG_MODE === "CONSOLE" || LOG_MODE === "BOTH") {      
       
       if(level === "WARN")  {
        console.warn(logEntry);
       }
       if(level === "ERROR"){
        console.error(logEntry)
       }
       if(level === "INFO" || level === "DEBUG"){
        console.log(logEntry)
       }       
    }
  }else{
    // console.log("logMessage - Skipping Logging due to log level")
  }
};

// Export logger methods for different levels
export default {
  logError: (source, message, meta) => logMessage("ERROR", source, message, meta),
  logWarn: (source, message, meta) => logMessage("WARN", source, message, meta),
  logInfo: (source, message, meta) => logMessage("INFO", source, message, meta),
  logDebug: (source, message, meta) => logMessage("DEBUG", source, message, meta),
};
