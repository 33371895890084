import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agent from '../api';
import logger from '../common/logger';

const loggingMetaName = 'Reducer: src/reducers/trainingMatchList.js';

export const getAllTrainingSessionMatches = createAsyncThunk(
  'trainingMatchList/getAllTrainingSessionMatches',
  async (sessionId, thunkAPI) => {
    try {
      logger.logDebug(`${loggingMetaName}`, `getAllTrainingSessionMatches called with sessionId: ${sessionId}`);
      const response = await agent.TrainingSessions.Matches.all(sessionId);
      return { sessionId, ...response };
    } catch (error) {
      logger.logError(`${loggingMetaName}`, `getAllTrainingSessionMatches triggered error: ${error}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  trainingSessionId: 0,
  trainingSessionMatches: [],
  trainingSessionMatchesCount: 0,
  searchQuery: '',
  loading: false,
  error: null,
};

const trainingMatchListSlice = createSlice({
  name: 'trainingMatchList',
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
    changeTab: (state, action) => {
      state.tab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTrainingSessionMatches.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTrainingSessionMatches.fulfilled, (state, action) => {
        const { sessionId, results = [], count = 0 } = action.payload;
        logger.logDebug(`${loggingMetaName}`, `getAllTrainingSessionMatches.fulfilled action.payload.results: ${results}`);
        state.trainingSessionId = sessionId;
        state.trainingSessionMatches = results || [];
        state.trainingSessionMatchesCount = count;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllTrainingSessionMatches.rejected, (state, action) => {
        logger.logError(`${loggingMetaName}`, `getAllTrainingSessionMatches.rejected action.error: ${action.error}`);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { homePageUnloaded } = trainingMatchListSlice.actions;

export default trainingMatchListSlice.reducer;