// src/reducers/report.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { chart_color_array } from "../common/colors";
import agent from '../api';
import logger from '../common/logger';

// Helper functions
const processSubmissions = (data) => {
  logger.logDebug('src/reducers/report.js', 'Processing submissions data', { data });
  let data_dict = data;

  let submission_report_labels = [
      ...new Set(data_dict.map((item) => item.timeLabel)),
  ];
  let dataset_labels = [
      ...new Set(data_dict.map((item) => item.technique_name)),
  ];

  let color_counter = 0;
  let submission_report_datasets = [];
  dataset_labels.forEach((element) => {
      // if (element == "Arm Bar") {
      let dataArray = [];
      Object.values(data_dict)
          .filter((value) => value.technique_name == element)
          .forEach((obj) => dataArray.push(obj["technique_count"]));

      let new_data_set = {
          type: "line",
          label: element,
          data: dataArray,
          backgroundColor: `${chart_color_array[color_counter]}`,
          borderColor: `${chart_color_array[color_counter]}`,
      };

      submission_report_datasets.push(new_data_set);
      color_counter++;
  });

  logger.logDebug('src/reducers/report.js', "submission_report_datasets ", submission_report_datasets);

  return { submission_report_datasets, submission_report_labels };
};

const processTapouts = (data) => {
  logger.logDebug('src/reducers/report.js', 'Processing tapouts data', { data });
  
  let data_dict = data;

        let tapout_report_labels = [
            ...new Set(data_dict.map((item) => item.timeLabel)),
        ];
        let dataset_labels = [
            ...new Set(data_dict.map((item) => item.technique_name)),
        ];

        let color_counter = 0;
        let tapout_report_datasets = [];
        dataset_labels.forEach((element) => {
            // if (element == "Arm Bar") {
            let dataArray = [];
            Object.values(data_dict)
                .filter((value) => value.technique_name == element)
                .forEach((obj) => dataArray.push(obj["technique_count"]));

            let new_data_set = {
                type: "line",
                label: element,
                data: dataArray,
                backgroundColor: `${chart_color_array[color_counter]}`,
                borderColor: `${chart_color_array[color_counter]}`,
            };

            tapout_report_datasets.push(new_data_set);
            color_counter++;
        });

        return { tapout_report_datasets, tapout_report_labels };
};

const processBeltSubmissions = (data) => {
  logger.logDebug('src/reducers/report.js', 'Processing belt submissions data', { data });
  let data_dict = data;

  let belt_submission_report_labels = [
      ...new Set(data_dict.map((item) => item.timeLabel)),
  ];
  let dataset_labels = [
      ...new Set(data_dict.map((item) => item.belt_name)),
  ];
  
  let belt_submission_report_datasets = [];
  dataset_labels.forEach((element) => {
      // if (element == "Arm Bar") {
      let dataArray = [];
      Object.values(data_dict)
          .filter((value) => value.belt_name == element)
          .forEach((obj) => dataArray.push(obj["belt_count"]));

      let new_data_set = {
          type: "bar",
          label: element,
          data: dataArray,
          backgroundColor: element,                
          borderWidth: 1,
          borderColor: "black",

      };

      belt_submission_report_datasets.push(new_data_set);            

  }); 

  return { belt_submission_report_datasets, belt_submission_report_labels };
};

const processBeltTapouts = (data) => {
  logger.logDebug('src/reducers/report.js', 'Processing belt tapouts data', { data });
  let data_dict = data;

  let belt_tapout_report_labels = [
      ...new Set(data_dict.map((item) => item.timeLabel)),
  ];
  let dataset_labels = [
      ...new Set(data_dict.map((item) => item.belt_name)),
  ];

  let color_counter = 0;
  let belt_tapout_report_datasets = [];
  dataset_labels.forEach((element) => {
      // if (element == "Arm Bar") {
      let dataArray = [];
      Object.values(data_dict)
          .filter((value) => value.belt_name == element)
          .forEach((obj) => dataArray.push(obj["belt_count"]));

      let new_data_set = {
          type: "bar",
          label: element,
          data: dataArray,
          backgroundColor: element,                
          borderWidth: 1,
          borderColor: "black",

      };


      belt_tapout_report_datasets.push(new_data_set);
      color_counter++;
  });

  return { belt_tapout_report_datasets, belt_tapout_report_labels };

};

const processHoursTrained = (data) => {
  logger.logDebug('src/reducers/report.js', 'Processing hours trained data', { data });

  let hours_label_array = [];                
  let hours_trained_data = [];
  let totalHourCount = 0;

  data.forEach((value) => {
      hours_label_array.push(value.month + "/" + value.year);        
      totalHourCount = totalHourCount + value.hours_trained;
      hours_trained_data.push(totalHourCount);
  });

  return { hours_trained_data, hours_label_array };  
};

// Async thunk for fetching reports
export const fetchReports = createAsyncThunk(
  'reports/fetchReports',
  async ({ duration = 365 } = {}, thunkApi) => {
    const state = thunkApi.getState();
    try {
      logger.logDebug('src/reducers/report.js', 'Fetching reports');

      const hoursTrainedReport = await agent.Reports.hoursTrained({duration: duration});    
      const beltSubmissionsReport = await agent.Reports.matchBeltLevelSubmissionDetails({duration: duration});      
      const beltTapoutsReport = await agent.Reports.matchBeltLevelTapoutDetails({duration: duration});      
      const submissionsReport = await agent.Reports.matchTechniqueSubmissionDetails({duration: duration});
      const tapoutsReport = await agent.Reports.matchTechniqueTapoutDetails({duration: duration});
      
      logger.logDebug('src/reducers/report.js', 'Fetched reports successfully');

      const processedSubmissions = processSubmissions(submissionsReport);
      const processedTapouts = processTapouts(tapoutsReport);
      const processedBeltSubmssions = processBeltSubmissions(beltSubmissionsReport);
      const processedBeltTapouts = processBeltTapouts(beltTapoutsReport);
      const processedHours = processHoursTrained(hoursTrainedReport);
      return {
        
        submission_report_datasets: processedSubmissions.submission_report_datasets,
        submission_report_labels: processedSubmissions.submission_report_labels,
        tapout_report_datasets: processedTapouts.tapout_report_datasets,
        tapout_report_labels: processedTapouts.tapout_report_labels,
        belt_submission_report_datasets: processedBeltSubmssions.belt_submission_report_datasets,
        belt_submission_report_labels: processedBeltSubmssions.belt_submission_report_labels,
        belt_tapout_report_datasets: processedBeltTapouts.belt_tapout_report_datasets,
        belt_tapout_report_labels: processedBeltTapouts.belt_tapout_report_labels,
        hours_trained_data: processedHours.hours_trained_data,
        hours_label_array: processedHours.hours_label_array,
      };
    } catch (error) {
      logger.logError('src/reducers/report.js', 'Error fetching reports', { error });
      throw error;
    }
  }
);


export const fetchReifersReport = createAsyncThunk(
  'reports/fetchReifersReport',
  async ({  duration = 365 } = {}, thunkApi) => {
    try {
      logger.logDebug('src/reducers/report.js', 'Fetching Reifers report');

      const reifersReportData = await agent.Reports.reifersReport({ duration });      

      const highestMatches = reifersReportData.highest_matches.map(item => ({
        opponentName: item.opponent_name,
        belt: item.match__opponent_belt__name,
        matches: item.matches,
        tapOuts: item.tap_outs,
        submissions: item.submissions,
        draws: item.draws,
      }));

      const highestTapOuts = reifersReportData.highest_tap_outs.map(item => ({
        opponentName: item.opponent_name,
        belt: item.match__opponent_belt__name,
        matches: item.matches,
        tapOuts: item.tap_outs,
        submissions: item.submissions,
        draws: item.draws,
      }));

      logger.logDebug('src/reducers/report.js', 'Fetched Reifers report successfully');

      return {
        highestMatches,
        highestTapOuts,
      };
    } catch (error) {
      logger.logError('src/reducers/report.js', 'Error fetching Reifers report', { error });
      throw error;
    }
  }
);


const getTopTechniques = (data, key) => {
  return data.technique_results
    .sort((a, b) => b[key] - a[key])
    .slice(0, 5)
    .map(item => ({ name: item.technique__name, value: item[key] }));
};


export const fetchReifersOpponentReport = createAsyncThunk(
  'reports/fetchReifersOpponnentReport',
  async (profileid, { duration = 365 } = {}, thunkApi) => {
    try {
      logger.logDebug('src/reducers/report.js', 'Fetching Reifers Opponent report');

      const reifersOpponentReportData = await agent.Reports.reifersReportByProfile(profileid, { duration });      

      const totals = reifersOpponentReportData.totals
      const topTapOuts = getTopTechniques(reifersOpponentReportData, 'technique_tap_outs');
      const topSubmissions = getTopTechniques(reifersOpponentReportData, 'technique_submissions');

      logger.logDebug('src/reducers/report.js', 'Fetched Reifers report successfully');

      return {
        totals,
        topTapOuts,
        topSubmissions,
      };
    } catch (error) {
      logger.logError('src/reducers/report.js', 'Error fetching Reifers report', { error });
      throw error;
    }
  }
);


const initialState = {
  reifers_opponent_report_data:{
    totals: {},
    topTapOuts: [],
    topSubmissions: []
  },
  reifers_report_data: {
    highestMatches: [],
    highestTapOuts: [],
  },
  submission_report_datasets: [],
  submission_report_labels: [],
  tapout_report_datasets: [],
  tapout_report_labels: [],
  belt_submission_report_datasets: [],
  belt_submission_report_labels: [],
  belt_tapout_report_datasets: [],
  belt_tapout_report_labels: [],
  hours_trained_data: [],
  hours_label_array: [],
  loading: false,
  error: null,
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    resetReportsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReports.pending, (state) => {
        state.loading = true;
        logger.logDebug('src/reducers/report.js', 'Fetching reports pending');
      })
      .addCase(fetchReifersOpponentReport.pending, (state) => {
        state.loading = true;
        logger.logDebug('src/reducers/report.js', 'Fetching reports pending');
      })
      .addCase(fetchReifersReport.pending, (state) => {
        state.loading = true;
        logger.logDebug('src/reducers/report.js', 'Fetching reports pending');
      })
      .addCase(fetchReifersReport.fulfilled, (state, action) => {
        state.reifers_report_data = {
          highestMatches: action.payload.highestMatches,
          highestTapOuts: action.payload.highestTapOuts,
        };
        state.loading = false;
        state.error = null;
        logger.logDebug('src/reducers/report.js', 'Fetching reports fulfilled', { state });
      })
      .addCase(fetchReifersOpponentReport.fulfilled, (state, action) => {
        state.reifers_opponent_report_data = {
          totals: action.payload.totals,
          topTapOuts: action.payload.topTapOuts,
          topSubmissions: action.payload.topSubmissions,
        };
        state.loading = false;
        state.error = null;
        logger.logDebug('src/reducers/report.js', 'Fetching reports fulfilled', { state });
      })
      .addCase(fetchReifersOpponentReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        logger.logError('src/reducers/report.js', 'Fetching Reifers report rejected', { error: action.error });
      })      
      .addCase(fetchReifersReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        logger.logError('src/reducers/report.js', 'Fetching Reifers report rejected', { error: action.error });
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        state.submission_report_datasets = action.payload.submission_report_datasets;
        state.submission_report_labels = action.payload.submission_report_labels;
        state.tapout_report_datasets = action.payload.tapout_report_datasets;
        state.tapout_report_labels = action.payload.tapout_report_labels;
        state.belt_submission_report_datasets = action.payload.belt_submission_report_datasets;
        state.belt_submission_report_labels = action.payload.belt_submission_report_labels;
        state.belt_tapout_report_datasets = action.payload.belt_tapout_report_datasets;
        state.belt_tapout_report_labels = action.payload.belt_tapout_report_labels;
        state.hours_trained_data = action.payload.hours_trained_data;
        state.hours_label_array = action.payload.hours_label_array;
        state.loading = false;
        state.error = null;
        logger.logDebug('src/reducers/report.js', 'Fetching reports fulfilled', { state });
      })
      .addCase(fetchReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        logger.logError('src/reducers/report.js', 'Fetching reports rejected', { error: action.error });
      });
  },
});

export const { resetReportsState } = reportsSlice.actions;

export default reportsSlice.reducer;