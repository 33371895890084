
/**
 * @typedef {Object} TrainingOutcome
  * @property {Integer} id
 * @property {String} practitioner_username
 * @property {String} practitioner_email
 * @property {Integer} practitioner_profile_id
 * @property {Integer} match_id
 * @property {Unknown} technique
 * @property {Boolean} submission
 * @property {Boolean} tap_out
 * @property {Boolean} executed
 * @property {Boolean} draw
 * @property {Boolean} incurred
 * @property {Boolean} attempted
 * @property {Boolean} escaped
 */



const TrainingOutcomes = (requests) => ({
    /**
     * Get all TrainingOutcomes
     *
     * @param {Object} query TrainingOutcome's query parameters
     * @returns {Promise<TrainingOutcomesResponse>}
     */
    all: (query) => requests.get(`/training-outcomes`, query),

    /**
     * Get one TrainingOutcome by ID
     *
     * @param {Number} id TrainingOutcome's ID
     * @returns {Promise<TrainingOutcomeResponse>}
     */
    get: (id) => requests.get(`/training-outcomes/${id}`),

    /**
     * Create a new TrainingOutcome
     *
     * @param {Object} trainingoutcome
      * @param {Integer} trainingoutcome.id
     * @param {String} trainingoutcome.practitioner_username
     * @param {String} trainingoutcome.practitioner_email
     * @param {Integer} trainingoutcome.practitioner_profile_id
     * @param {Integer} trainingoutcome.match_id
     * @param {Unknown} trainingoutcome.technique
     * @param {Boolean} trainingoutcome.submission
     * @param {Boolean} trainingoutcome.tap_out
     * @param {Boolean} trainingoutcome.executed
     * @param {Boolean} trainingoutcome.draw
     * @param {Boolean} trainingoutcome.incurred
     * @param {Boolean} trainingoutcome.attempted
     * @param {Boolean} trainingoutcome.escaped
     * @returns {Promise<TrainingOutcomeResponse>}
     */
    create: (matchId, trainingoutcome) => requests.post(`/training-matches/${matchId}/outcomes/`,  trainingoutcome ),

    /**
     * Update one TrainingOutcome
     *
     * @param {Number} id TrainingOutcome's ID
     * @param {Partial<TrainingOutcome>} trainingoutcome
     * @returns {Promise<TrainingOutcomeResponse>}
     */
    update: (id, trainingoutcome) => requests.put(`/training-outcomes/${id}`, { trainingoutcome }),

    /**
     * Partially update one TrainingOutcome
     *
     * @param {Number} id TrainingOutcome's ID
     * @param {Partial<TrainingOutcome>} trainingoutcome
     * @returns {Promise<TrainingOutcomeResponse>}
     */
    partialUpdate: (id, trainingoutcome) => requests.patch(`/training-outcomes/${id}`, { trainingoutcome }),

    /**
     * Remove one TrainingOutcome
     *
     * @param {Number} id TrainingOutcome's ID
     * @returns {Promise<{}>}
     */
    del: (id) => requests.del(`/training-outcomes/${id}`),
});

export default TrainingOutcomes;
